<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M90.6667 137.708L118.292 165.333L164.333 100.875M238 128.5C238 144.072 230.27 157.838 218.437 166.17C220.91 180.427 216.642 195.625 205.632 206.635C194.623 217.644 179.425 221.913 165.168 219.44C156.836 231.271 143.071 239 127.5 239C111.93 239 98.165 231.271 89.8323 219.441C75.573 221.917 60.3719 217.649 49.3606 206.637C38.3494 195.626 34.0814 180.425 36.5568 166.166C24.7277 157.833 17 144.069 17 128.5C17 112.93 24.7284 99.1656 36.5582 90.8328C34.0842 76.5747 38.3523 61.3754 49.3626 50.3651C60.3731 39.3546 75.5728 35.0865 89.831 37.5608C98.1636 25.7295 111.929 18 127.5 18C143.07 18 156.835 25.7288 165.168 37.5593C179.427 35.0839 194.628 39.3517 205.639 50.363C216.651 61.3742 220.919 76.5749 218.443 90.8339C230.272 99.1668 238 112.931 238 128.5Z"
      stroke="currentColor"
      stroke-width="15"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
